

import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ChicagoTribune = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <p><h2>A Streetwise Rabbi Connects with Lost Kids</h2>
      <em>The seeds of positive growth or benign neglect of teens are nurtured in the family environment.'</em><br/><br/>

[image not found] Rabbi Yehudah Fine in Times Square: Rescuing the throwaways. (Photo for the Tribune by Jim Cooper)

The first clue that Yehudah Fine is not your everyday American rabbi is the New York Yankees baseball hat tipped back on his head in place of a yarmulke. He introduces himself as Yehudah, insisting, "Please don't call me rabbi. There are too many titles in this world."<br/><br/>

He may look like a rabbi, with his neatly manicured beard and glasses, but he's as unorthodox as they come. For 10 years his congregation was a gritty mixture of white, black, Jewish, Christian, wealthy and inner-city homeless teenagers inhabiting the streets of New York's Times Square. He's articulate, soft-spoken and scholarly, yet he loved prowling the dark, rat-infested subway tunnels and garbage piles to reach teens no one else wanted. During these forays, the rabbi quietly found and nurtured hundreds of kids back from the edges of society.<br/><br/>

"Caring about teenagers is not a popular concept, but it's immensely important. When I was out on the streets, so many people told me I was wasting my time, that I was throwing away so much to help kids who were already goners. Hey, no one said it was going to be easy," acknowledges Fine. "But if we don't pay attention to our kids, who will? We must put as much prime time into our kids as we put into our jobs."
<br/><br/>
The message he brings to parents and teens from his book "The Times Square Rabbi: Finding the Hope in Lost Kids' Lives" (Hazelden, $14.95) is that even when a teenager's life is in crisis, caring can produce profound transformation.
<br/><br/>

Now the Times Square Rabbi crisscrosses the country talking to teens, parents and educators as the violence in schools has escalated. His unique street experiences opened up a window into the world of disconnected teens, and Fine's message should resonate in every home: "The seeds of positive growth or benign neglect of teens are nurtured in the family environment. Violent kids come from a vacuum of neglect with no contact or intimacy."<br/><br/>

As the son of a Seattle physician who entertained Israeli dignitaries such as former Prime Minister Golda Meir, Fine learned early on about the importance of positive growth.<br/><br/>

With a backpack of academic degrees in biology, agriculture, education and school administration, he started an alternative high school for teens that won an award as the most creative secondary school in California. He then decided to fulfill his spiritual side and became a rabbi.<br/><br/>

"But when I met with families, I realized I needed more training. I knew what to ask, but did not understand the process, about peoples' struggles," explains the 52-year-old Fine. In typical fashion, Fine talked his way into a prestigious family therapy training facility in Manhattan.<br/><br/>

Effie Nulman, dean of Yeshiva University in New York City, defines Fine as having a "spiritual glow about him, but you have to understand that he's a dynamic trained family therapist as well as a rabbi. Truthfully, he stands head and shoulders above everyone else. Teens can tell when someone is real – you can't fool them. Every kid who meets him says he's someone special."<br/><br/>

Fine began his unique outreach to teens in New York by tagging along with Covenant House's Off the Streets program a few nights each week, reaching teenagers who no longer had families who wanted them. Something clicked, and the next decade of his life was spent helping kids in the dark subway tunnels and streets of the Bronx, Manhattan and Queens. Word spread about this rabbi who found the "throwaway kids" and helped them put their lives back together.<br/><br/>

Distraught parents asked for his help in finding their teenagers, and Fine never charged them a penny. He supported his work through being on the guidance staff at Yeshiva University and his seminars.<br/><br/>

And along the way he absorbed everything about these kids and why they hurt and how to heal, and taught them to forgive themselves and others. He opened his home to them. Mostly, he gave them hope by showing that someone cared about whether they lived or died.<br/><br/>

Among the many teens Fine writes about in his book is Sarah, who was kicked out of her house. Today Sarah has a master's degree in social work, is married and recently had a baby girl. The mere mention of his name brings tears of gratitude.<br/><br/>


"Yehudah is larger than life. Teens relate to him because he has genuine love for people," she says. "He captivates you. He cares."<br/><br/>

In 1997  he finally decided to spend more time with his family and moved to upstate New York with his wife, daughter Dorah, 20, and sons Benny, 18, and Rafi, 16. As he completes his early morning run through the Catskill Mountains with his black labrador retriever, a defining moment from his Times Square days plays constantly in his head.<br/><br/>

"One night around 2 a.m., in the dead of winter, I was up on the East Side of Manhattan with an outreach team from a local runaway and homeless youth agency. I was sitting by the sliding door of the van talking with a group of kids when I heard and saw a guy firing his gun in the air. Then I saw two kids running straight toward me. When they were about six feet away, they both leaped into the air and landed on top of me.<br/><br/>

" 'Get the hell out of here!' they screamed at the driver. He hit the accelerator and off we flew into the night with the van door wide open and six legs sticking out the side.<br/><br/>

"I asked, 'Why did you risk your lives to save me?' I wasn't tight with these guys. They sure weren't angels, and this was not Hollywood. They had grown up with violence. Why did these supposed rejects of society save me?<br/><br/>

"They answered, 'Yehudah, that guy was so beamed out on crack that he was gonna come down the street and shoot you. If you got killed out here, then caring would go off the streets.' These kids placed caring so high on their list of things they would die for, it made me realize how much teens value friendship and loyalty. And these two young men who saved my life disappeared into the night and I never saw them again."<br/><br/>

That lesson helped crystallize his understanding into what motivates teens. When invited into high schools, Fine divides kids into smaller groups, talks about life, asks them to write down questions, and spends the rest of the day in the hallways or lunchroom, "schmoozing" with the kids.<br/><br/>

His blend of Talmudic quotes mingled with street talk and humor grabs teens' attention.<br/><br/>

"Kids say I'm 'straight out' with them. They mean I get right to the point," Fine laughs. "I've met people, especially inner-city kids, who had never heard of a rabbi. I've had non-Jewish kids hang with me, then tell people they were rabbis now too, because a rabbi is cool and says it straight, and so if they're cool and say it straight, they must be rabbis too."<br/><br/>

Everywhere he goes, he asks teens about their true feelings and fears.<br/><br/>

"I ask, 'How many of you have friends who are contemplating suicide?' and more than 90 percent of kids raise their hands; 65 percent of their questions and concerns deal with their friends' drug problems, suicide attempts and depression. It always comes down to worrying about their friends.<br/><br/>

"So then I ask, 'What if I opened my suitcase and pulled out 500 joints of the best stuff on the planet, and we'll all get high? Would you like that?' Of course they love the idea, until I ask, 'Now, how many of you will become junkies from smoking this first joint?'<br/><br/>

"Don't forget, kids learn about drugs from their friends, not health class. So they look at each other and say, 'You can't become a junkie from smoking one joint!' And I tell them they're right, for more than 90 percent of us.<br/><br/>

"But a certain percentage of the population is genetically predisposed to addiction. When that person gets high the first time, they have a totally different physiological response. It's like, 'Wow! I'm home!' So then I ask, 'Who are these people? You don't know. But it could be your best friend. Now do you think it's OK to party and introduce your friends to pot? How responsible is that?'<br/><br/>

"It's a totally different way of looking at things, and kids respond dramatically. Once you get teens to think about the consequences, and how it can affect their friends' lives, it really changes how they look at things."<br/><br/>

Last spring Fine spent two days at the Grace-Webb School for Living in Hartford, Conn. At this non-denominational alternative school for children struggling with social and emotional problems, "these kids had bottomed out and didn't trust any adults," he remembers.<br/><br/>

Kate Morgan, a school therapist, watched as the school's 40 high school students were turned over to Fine.<br/><br/>

"He just tuned in to these high-risk kids and could pinpoint their problems right away. Suddenly he blended with them. It was so powerful to watch these kids open up," Morgan says. "At the end of two days, they were amazed by how much they had bonded to Yehudah. They didn't want him to go. They each wanted a little piece of him."<br/><br/>

Elaine Johnson runs the America Online Addictions and Recovery site for adults and teens, where Fine hosts a monthly forum on forgiveness.<br/><br/>

"Yehudah is a healer. He has an energy that we don't see often in our lifetime," she says. "His forgiveness conferences replace a negative idea with a positive attitude. People leave with hope and optimism for a better life."<br/><br/>

How is Fine handling all the attention and calls for his advice?<br/><br/>

"I simply care and chose not to sit on the sidelines. Who in life can wait for all the press releases and talks to finish when there is work to be done?"
<hr/>
</p>
    </Layout>
  )
}

export default ChicagoTribune

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`